import SubscribeForm from './SubscribeForm'

export function SponsorEnquiry() {
	return(
		<main className="main">
		<div style={{color: 'white', padding: '1rem 2rem 1rem', margin: '3rem 0 1rem', background: "rgb(8 42 81 / 83%)"}}>
		<h2>Are your customers DCM professionals? Advertise on DCM Insider</h2>
		<p>Our advertisers help us keep DCM Insider free. Connect with DCM professionals around the world by advertising on DCM Insider.</p>
					<p>You get your message in front of debt capital markets profesionals multiple times a week.</p>
		<p>DCM Insider's news alerts are read by over 15,000 investors, issuers, bankers, lawyers and other professionals.</p>
		<p>We make sure that adveriting is cost effective, and offer advertiers a trial period to make sure that advertising on DCM Insider is right for their business.</p>
					<p>Contact us at sponsorship@dcminsider.com to learn more.</p>
		</div>
		</main>
	)
}
