import SubscribeForm from './SubscribeForm'
import RecentPosts from '../RecentPosts'

export function GetNewsAlerts({db}) {
	return(
		<main className="main">
		<div style={{marginTop: '40px', padding: '1.1rem 0 0.8rem', background: "rgb(8 42 81 / 83%)"}}>
		{/*<p style={{fontSize: '1.7em', maxWidth: '600px', margin: '0 2rem', color: 'white'}}>Be a DCM Insider.</p>*/}
		<p style={{fontSize: '1.7em', maxWidth: '600px', margin: '0 2rem 0.8rem', color: 'white'}}>Stay up to date with debt capital markets news</p>
		<p style={{color: 'white', padding: '0rem 2rem 0.8rem'}}>Get DCM news by email for free. Stay up to date with the important deals and developments across public, private and structured credit markets.</p>
		<div style={{maxWidth: '408px', margin: '14px 32px 10px'}}>
	<SubscribeForm style={{background: 'blue', border: '3px solid blue'}} />
		</div>
		</div>

		</main>
	)
}
