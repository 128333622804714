import React, { useState, useEffect } from 'react';
import { useParams , Link } from 'react-router-dom';
import { collection, query, where, getDocs } from 'firebase/firestore';
import SubscribeForm from './SubscribeForm'
import EmailLinkAuth from './EmailLinkAuth'

function PostPage({db, user}) {
	const [postContent, setPostContent] = useState("");
	const [postTitle, setPostTitle] = useState("");
	const [postAuthor, setPostAuthor] = useState("");
	const [postDate, setPostDate] = useState("");
	const [userIsPaidSubscriber, setUserIsPaidSubscriber] = useState(false);
	const [isSubscriberOnly, setIsSubscriberOnly] = useState(false);
	const [premiumAvailable, setPremiumAvailable] = useState(false);
	const {urlStub} = useParams();

	useEffect(() => {
		async function fetchPost() {
			const postsRef = collection(db, 'posts');
			const q = query(postsRef, where('url-index', '==', urlStub));
			const querySnapshot = await getDocs(q);

			if(!querySnapshot.empty) {
				const postDoc = querySnapshot.docs[0].data();
				setIsSubscriberOnly(postDoc.subscriberOnly);
				setPremiumAvailable(postDoc.premiumAvailable);
				setUserIsPaidSubscriber(false);
				if(user) {
					setPostContent(postDoc.registeredContent);
				}
				else {
					//setPostContent(postDoc.openContent);
					setPostContent(postDoc.registeredContent);
				}
					setPostTitle(postDoc.title);
					setPostAuthor(postDoc.author);
					setPostDate(postDoc.postDate?.toDate().toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric', }));
			} else {
				setPostContent('Post not found');
			}
		}

		fetchPost();
	}, [db, urlStub, user]);


	if(false && isSubscriberOnly && (!user || !user.isPaidSubscriber)) {
		return(
			<div className="article">
			<h1 className="title">Subscriber-Only Content</h1>
			<span className="date">{postDate}</span>
			<div className="metaData">
			<span className="author">{postAuthor}</span>
			</div>
			<p>This content is available only to paid subscribers.</p>
			{!user && <p>Please <Link to="/login">log in</Link> if you're already a subscriber.</p> }
				{user && !user.isPaidSubscriber && <p>To access this content, please <Link to="/subscribe">subscribe</Link>.</p>}
				</div>
			);}




			return (
		<main className="main">
				<article className="article" style={{background: 'rgb(255 255 255 / 90%'}}>
				{postDate ? <p style={{color: 'grey', marginTop: '0.8rem', marginBottom: '0.8rem'}}>DCM News</p> : <p></p>}
				<h1 className="title">{postTitle}</h1>
				<div className="content" dangerouslySetInnerHTML={{__html: postContent}} />
				<div className="metaData">
				<p><span className="author">{postAuthor}</span></p>
				<span className="date" style={{fontSize: '0.8rem'}}>{postDate}</span>
				</div>
				</article>
					<div style={{backgroundColor: 'rgb(8 42 81 / 83%)', marginTop: '1rem', color: 'white', borderTop: 'solid grey 1px', padding: '30px'}}>

		<p style={{fontSize: '1.7em', maxWidth: '600px', margin: '0 0rem 0rem', color: 'white'}}>Stay up to date with debt capital markets news</p>
		<p style={{color: 'white', padding: '0rem 0rem 0rem'}}>Get DCM news by email for free. Stay up to date with the important deals and developments across public, private and structured credit markets.</p>
					<SubscribeForm />

					</div>
		<div style={{padding: '1.9rem 0 1rem', margin: '1rem 0 1rem', background: "rgb(10 10 10 / 83%)"}}>
		<h2 style={{color: 'white', padding: '0rem 2rem 0rem'}}>Advertise on DCM Insider</h2>
		<p style={{color: 'white', padding: '0rem 2rem 0rem'}}>Reach DCM professionals around the world: <a style={{color: 'white'}} href="/sponsor-enquiry">read more.</a></p>
		</div>
		<div style={{marginTop: '1rem', padding: '1.1rem 0 0.8rem', background: "rgb(73 102 135 / 83%)"}}>
		<p style={{color: 'white', padding: '0rem 2rem 0.8rem'}}>DCM Insider provides news services for debt capital markets professionals. We work to increase the speed of information dissemination at the leading edges of the market. As the world's largest capital market, improvements in the debt capital markets positively affect billions of people around the world.
		</p>
		</div>
				</main>
			);
	}

	export default PostPage;

