import React, { useState } from 'react';
import { getAuth, sendSignInLinkToEmail } from 'firebase/auth';
import { getFirestore, doc, addDoc, collection, serverTimestamp } from 'firebase/firestore';

const SubscribeForm = () => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);

  const handleSignup = async (e) => {
    e.preventDefault();
    setError('');

    // Basic validation
    if (!email ) {
      setError('Please enter your email address');
      return;
    }

    // Email format validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setError('Please enter a valid email address');
      return;
    }

    try {
      const db = getFirestore();
      
      // Prepare user data for Firestore
      const userData = {
        email,
        createdAt: serverTimestamp(),
        status: 'subscribed' // You can use this to track signup completion
      };

      await addDoc(collection(db, 'subscribers'), userData);

      setSuccess(true);
    } catch (error) {
      console.error('Subscribe error:', error);
      setError('Failed to subscribe. Please try again.');
    }
  };

  if (success) {
    return (
      <div className="signup-container">
        <div className="signup-card">
          <h2>Successfully subscribed</h2>
          <p>You have successfully subscribed to DCM news alerts.</p>
        </div>
      </div>
    );
  }

  return (
    <div className="signup-container">
      <div className="signup-card">
        <form onSubmit={handleSignup} className="signup-form">
          {error && (
            <div className="error-message">
              {error}
            </div>
          )}
          
          <div className="form-group">
		{/*<label htmlFor="email">Email Address</label>*/}
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter your email"
              required
            />
          </div>
          
          
          <button type="submit" className="signup-button">
            Subscribe
          </button>
        </form>
      </div>
    </div>
  );
};

export default SubscribeForm;

