import React from 'react';
import {Link} from 'react-router-dom';
import RecentPosts from '../RecentPosts'
import SubscribeForm from './SubscribeForm'

function HomePage({user, db}){
	return (
		<div>
		<main className="main homepg">
				<div style={{paddingTop: '0px', paddingBottom: '0px', paddingLeft: '0', paddingRight: '0'}}>


		<div style={{padding: '1.1rem 0 0.8rem', background: "rgb(8 42 81 / 83%)"}}>
		<p style={{fontSize: '1.7em', maxWidth: '600px', margin: '0 2rem 0.8rem', color: 'white'}}>Stay up to date with debt capital markets news</p>
		<p style={{color: 'white', padding: '0rem 2rem 0.8rem'}}>Get DCM news by email for free. Stay up to date with the important deals and developments across public, private and structured credit markets.</p>
		{/*<p style={{fontSize: '1.7em', maxWidth: '600px', margin: '0 2rem', color: 'white'}}>Be a DCM Insider.</p>*/}
		<div style={{maxWidth: '408px', margin: '14px 32px 10px'}}>
	<SubscribeForm style={{background: 'blue', border: '3px solid blue'}} />
		</div>
		</div>
		<div style={{padding: '1rem 0 1rem', margin: '1rem 0 0rem', background: "rgb(7 88 129 / 83%)"}}>
		<h2 style={{fontWeight: 'normal', color: 'white', marginTop: '10px', marginBottom: '0px', paddingLeft: '2rem', paddingRight: '2rem', fontSize: '1.4rem'}}>Latest news</h2>
		</div>

		<div style={{border: 'solid 5px rgb(7 88 129 / 83%)'}}>
		<RecentPosts db={db} />
		</div>
		{/*
		{user ? (
			<p>Welcome, {user.email}! {!user.isPaidSubscriber && <Link to='/subscribe'>Subscribe</Link>}</p>) : (
				<p><Link to="login">Log in</Link> or <Link to="/subscribe">Subscribe</Link></p>
			)}
			*/}
		</div>
		<div style={{marginTop: '1rem', padding: '1.1rem 0 0.8rem', background: "rgb(73 102 135 / 83%)"}}>
		<p style={{color: 'white', padding: '0rem 2rem 0.8rem'}}>DCM Insider provides news services for debt capital markets professionals. We work to increase the speed of information dissemination at the leading edges of the market. As the world's largest capital market, improvements in the debt capital markets positively affect billions of people around the world.
		</p>
		</div>
	</main>
		</div>
	);
}

export default HomePage;
