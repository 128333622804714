import React, {useState, useEffect} from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAnalytics } from "firebase/analytics";
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth';
import HomePage from './components/HomePage';
import PostPage from './components/PostPage';
import { GetNewsAlerts } from './components/GetNewsAlerts'
import { SponsorEnquiry } from './components/SponsorEnquiry'
import LoginPage from './components/LoginPage';
import SubscribePage from './components/SubscribePage'
import SubscriptionEnquiry from './SubscriptionEnquiry'
import CompleteSignUp from './components/CompleteSignUp'

import './App.css';
import './index.css';



const firebaseConfig = {
  apiKey: "AIzaSyD-4weM8E7ujPUigFXIlYDWjNAEsmg6bkc",
  authDomain: "dcm-data-e34ff.firebaseapp.com",
  projectId: "dcm-data-e34ff",
  storageBucket: "dcm-data-e34ff.firebasestorage.app",
  messagingSenderId: "633911493960",
  appId: "1:633911493960:web:b4a6abd66f625b49151f7d",
  measurementId: "G-1NY6FT0CXB"
};


// initialize firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app)
const auth = getAuth(app);
const analytics = getAnalytics(app);


function LogoutButton({user, setUser}) {
	const [error, setError ] = useState(null);
	const auth = getAuth();

	const handleLogout = async (setUser) => {
		try {
			await signOut(auth);
			setUser(null)
			console.log('signed out');
		} catch (error) {
			setError(error.message);
			console.error("Logout error:", error);
		}
	};
	return (
		<div>
		<button
		onClick={handleLogout}
		className="logoutButton">
		Log out
		</button>
		</div>
	)
}

function LogInButton() {
	return (
		<div>
		<a href="/get-news-alerts" className="link">Get News Alerts</a>
		</div>
	)
}

function Header({user, setUser}) {
	return (
		<div className="header">
		<nav className="nav">
		{/* <a style={{textDecoration: "none"}} href="/"><h1 className="logo">DCM Insider</h1></a> */}
		<a href="/">
		<img style={{width: "120px"}} src="/logo512.png" />
		</a>
		<div className="nav-links">
		<a href="/" className="link">Home</a>
		{/*
		<a href="/blog" className="link">Blog</a>
		<a href="/about" className="link">About</a>
		<a href="/contact" className="link">Contact</a>
		*/}
		{user ? <LogoutButton user={user} setUser={setUser} /> : <LogInButton /> }
		</div>
		</nav>
		</div>
	)
}


function Footer() {
	return(
		<div className="footer">
		<div className="footer-content">
		{/*
		<div className="footer-section">
		<h3 className="footer-title">About Blog</h3>
		<p>A blog about web development and programming.</p>
		</div>
		<div className="footer-section">
		<h3 className="footer-title">Quick Links</h3>
		<a href="/privacy" className="footer-link">Privacy Policy</a>
		<a href="/terms" className="footer-link">Terms of Service</a>
		</div>
		<div className="footer-section">
		<h3 className="footer-title">Contact</h3>
		<p>Email: contact@blog.com</p>
		</div>
		*/}
		</div>
		<div className="copyright">
		<a href="/about">About DCM Insider</a> | <a href="/contact">Contact DCM Insider</a> 
		</div>
		<div className="copyright">
		<a href="/site-terms">Site Terms</a> | <a href="/privacy-policy">Privacy Policy</a>
		<span style={{marginLeft: '5px'}}>Copyright 2025</span>
		</div>
		<div className="copyright">
		</div>
		</div>
	)
}




function App() {
	const [user, setUser] = useState(null);

	useEffect(() => {
		const unsubscribe = onAuthStateChanged(auth, user => {
			setUser(user);
		});
		return () => unsubscribe();
	}, []);

	const [menuOpen, setMenuOpen] = useState(false);

	return (
		<Router>
		<div className="container homepage-background">
		<Header user={user} setUser={setUser} />
		<Routes>
		<Route path="/" element={<HomePage user={user} db={db} />} />
		<Route path="/get-news-alerts" element={<GetNewsAlerts db={db} />} />
		<Route path="/sponsor-enquiry" element={<SponsorEnquiry />} />
		<Route path="/login" element={<LoginPage />} />
		<Route path="/subscription-enquiry" element={<SubscriptionEnquiry db={db} />} />				
		<Route path="/subscribe" element={<SubscribePage user={user} app={app} />} />
		<Route path="/complete-signup" element={<CompleteSignUp user={user} app={app} />} />
		<Route path="/:urlStub" element={<PostPage db={db} user={user} />} />				
		</Routes>
		<Footer />
		</div>
		</Router>
	);
}

export default App;
