import React, { useState, useEffect } from 'react';
import { collection, query, orderBy, limit, getDocs } from 'firebase/firestore';
import { Link } from 'react-router-dom';

const RecentPosts = ({db}) => {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchRecentPosts = async () => {
      try {
        // Create a query to get the 10 most recent posts
        const postsQuery = query(
          collection(db, 'posts'), 
          orderBy('postDate', 'desc'), 
          limit(3)
        );

        // Execute the query
        const querySnapshot = await getDocs(postsQuery);
        
        // Map the documents to an array of post objects
        const fetchedPosts = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));

        setPosts(fetchedPosts);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching articles', err);
        setError(err);
        setLoading(false);
      }
    };

    fetchRecentPosts();
  }, []);

  if (loading) {
    return <div style={{paddingLeft: '2rem', paddingRight: '2rem', padding: '30px'}}>Loading articles...</div>;
  }

  if (error) {
    return <div>Error loading posts: {error.message}</div>;
  }

  return (
    <div className="recent-posts" style={{padding: '14px 0', background: 'rgb(255 255 255 / 90%'}}>
      {posts.map(post => (
        <div className="latestPostsEntry" key={post.id}>
          <Link to={`/${post['url-index']}`}>
						<img class="recentPostKeyVisual" src={post.keyVisual} />
						<p style={{marginBottom: '0.5em'}}>{post.title}</p>
						<p style={{color: 'grey', marginTop: '3px', marginBottom: '0.5em'}}>{post.excerpt}</p>
						<p style={{color: 'grey', marginTop: '3px', fontSize: '0.8em'}}>{post.postDate?.toDate().toLocaleDateString('en-US', {year: 'numeric', month: 'long', day: 'numeric',}) }</p>
          </Link>
        </div>
      ))}
    </div>
  );
};

export default RecentPosts;
